@tailwind base;
@tailwind components;
@tailwind utilities;

/* .animated-card {
    transform: rotateY(0deg) scale(1);
    opacity: 0.8;
    transition: transform 0.8s ease, opacity 0.8s ease;
    background-color: black;
}

.animated-card:hover {
    transform: rotateY(360deg);
    opacity: 1;
}

#myDiv {
    transform: rotateY(0deg) scale(1);
    opacity: 0.8;
    transition: transform 0.8s ease, opacity 0.8s ease;
    }
#myDiv:hover {
    transform: rotateY(360deg);
    opacity: 1;
} */

.flip-card {
    background-color: transparent;
    /* width: 300px; */
    /* height: 100%; */
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    /* background-color: #bbb; */
    height: 100%;
    /* color: black; */
  }
  
  .flip-card-back {
    /* background-color: #2980b9; */
    /* color: white; */
    transform: rotateY(180deg);
  }

/* Optional: Fade-in animation on load
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.animated-card {
    animation: fadeIn 0.8s ease forwards;
} */
